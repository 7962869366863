<template>
  <div class="container">
    <h1>{{ $t('DISCLOSURE_SUCCESS.TITLE') }}</h1>

    <CardMessage theme="eco" :icon="sentIcon">
      <strong>{{ code }}</strong>
      <p>
        {{ $t('DISCLOSURE_SUCCESS.DESCRIPTION') }}
      </p>
    </CardMessage>

    <router-link :to="companyLink" class="s-button eco small outline">
      <img :src="backIcon" :alt="$t('NOT_FOUND_BTN')" />
      {{ $t('NOT_FOUND_BTN') }}
    </router-link>
  </div>
</template>

<script>
import CardMessage from '@/components/CardMessage'
import backIcon from '@/assets/images/issues/back_eco_icon.svg'
import sentIcon from '@/assets/images/icon_disclosure_sent.svg'

export default {
  name: 'DisclosureSuccess',

  components: {
    CardMessage,
  },

  data() {
    const { code } = this.$route.params

    return {
      code,
      backIcon,
      sentIcon,
    }
  },

  computed: {
    companyLink() {
      return { name: 'company', params: this.$route.params }
    },
  },

  async beforeMount() {
    if (this.$store.getters.isLoggedIn) {
      return
    }

    const { slug, slugId, agree } = this.$route.params

    if (!agree) {
      this.$router.push({ name: 'company', params: { slug, slugId } })
    }
  },
}
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 33px 36px;
}
.container h1 {
  margin: 50px auto;
  color: var(--color-green-default);
}

.container .s-button.eco {
  display: flex;
  align-items: center;
}

.container .s-button.eco img {
  margin-right: 10px;
  height: 12px;
}
</style>
